import React, { useContext, useEffect, useState } from "react"
import _isEmpty from "lodash/isEmpty"
import _get from "lodash/get"
import _values from "lodash/values"
import _map from "lodash/map"

import SEOHeader from "../components/seo-header"
import Layout from "../components/layout"
import AuthContext from "../contexts/AuthContext"
import { database } from "../../firebaseConfig"
import { equalTo, onValue, orderByChild, query, ref } from "firebase/database"
import BookingCard from "../components/BookingCard"
import BookingCardSmall from "../components/BookingsCardSmall"

const BookingDetailScreen = props => {
  const params = new URLSearchParams(props.location.search)
  const bookingKey = params.get("booking_id")
  const [loading, setLoading] = useState(false)
  const [booking, setBooking] = useState({})
  const { user } = useContext(AuthContext)

  useEffect(() => {
    setLoading(true)
    const bookingRef = ref(database, `bookings/${bookingKey}`)
    onValue(bookingRef, snapshot => {
      if (snapshot.exists()) {
        console.log({ bookingRefValue: snapshot.val(), bookingRef })
        console.log(snapshot.val())
        setBooking(snapshot.val())
      } else {
        setBooking({})
      }
      setLoading(false)
    })
  }, [bookingKey])

  const renderBookingDetail = () => {
    return <BookingCard bookingInfo={booking} user={user} />
  }

  const renderNonLoggedInContent = () => {
    return (
      <p style={{ textAlign: "center" }}>Please login to view the bookings.</p>
    )
  }

  const renderContent = () => {
    if (_isEmpty(user)) return renderNonLoggedInContent()
    return renderBookingDetail()
  }

  return (
    <div>
      <SEOHeader
        title={"Book Spaces | GoFloaters"}
        description={"Book Spaces | GoFloaters"}
      ></SEOHeader>

      <Layout>
        <div className="container">
          <div className="row">
            <h4>Booking Details</h4>
          </div>
          <div className="row">{renderContent()} </div>

          <div className="row">
            <br />
            <br />
          </div>
        </div>
      </Layout>
    </div>
  )
}
export default BookingDetailScreen
